import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography, TextField, IconButton } from "@mui/material";
import apiRequest from "../../utils/api.js";
import CommonTable from "../common/CommonTable.js";
import { Pagination } from "@mui/material";
import moment from "moment";
import TableCell from "@mui/material/TableCell/index.js";
import CommonButton from "../common/CommonButton.js";
import {
  ArrowBackIos,
  ArrowForwardIos,
  SearchOutlined,
  FilterAlt,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles/index.js";
import { useNavigate } from "react-router-dom";
import FiltersDrawer from "../common/FiltersDrawer.js";
import WalletListFilters from "./WalletListFilters.js";
import useDebounce from "../../customHook/useDebounce.js";

const WalletList = () => {
  const [walletData, setWalletData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filters, setFilters] = useState({});

  const navigate = useNavigate();

  const fetchWalletData = useCallback(
    async (searchText) => {
      const res = await apiRequest("/get-wallets", "POST", {
        page: page,
        searchText,
        ...filters,
      });
      if (res?.success) {
        setWalletData(res?.data?.walletList);
        setTotalPages(res?.data?.totalCount);
      }
    },
    [page, filters]
  );

  const handleRowClick = (row) => {
    navigate(`/wallets/${row?.application?._id}/${row?.address?.key}`);
  };

  const handleNext = () => {
    if (page < Math.ceil(totalPages / 10)) {
      setPage(page + 1);
    }
  };
  const CustomPagination = styled(Pagination)(({ theme }) => ({
    "& .MuiPaginationItem-previousNext": {
      display: "none",
    },
  }));

  const tableHeaders = [
    "Application",
    "Blockchain",
    "Network",
    "Account ID",
    "Wallet Address",
    "Seed Index",
    "Last Used",
    "Status",
    "Approx USD Balance",
  ];
  const renderRow = (row) => {
    return (
      <>
        <TableCell component="th" scope="row">
          {row?.application?.applicationId
            ? row?.application?.applicationId
            : "NA"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.blockchain ? row?.blockchain : "NA"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.network ? row?.network : "NA"}
        </TableCell>{" "}
        <TableCell component="th" scope="row">
          {row?.applicationUserId ? row?.applicationUserId : "NA"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.address?.key
            ? row?.address?.key.substring(0, 6) +
              "..." +
              row?.address?.key.substring(row?.address?.key.length - 6)
            : "NA"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.address?.seedIndex ? row?.address?.seedIndex : "NA"}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.balanceUpdated
            ? moment(row?.balanceUpdated).format("DD/MM/YYYY HH:mm")
            : "NA"}
        </TableCell>
        <TableCell component="th" scope="row">
          <span
            className={`bg-[#F4F4F5] px-4 py-2 rounded-3xl ${
              row?.status?.code === "Available" &&
              ` bg-[#e8f8ee] text-[#027A48] `
            }${
              row?.status?.code === "Locked" && ` bg-[#FEF3F2] text-[#B42318] `
            }${
              row?.status?.code === "In-Use" && ` bg-[#FFFAEB] text-[#B54708]`
            }`}
          >
            {row?.status?.code ? row?.status?.code : "NA"}
          </span>
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.balanceAmountUSDApprox ? row?.balanceAmountUSDApprox : "NA"}
        </TableCell>
      </>
    );
  };

  useEffect(() => {
    fetchWalletData();
  }, [page, fetchWalletData]);
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const debouncedSearch = useDebounce((searchText) => {
    fetchWalletData(searchText);
  }, 500);
  
  const handleChangeSearchText = (e) => {
    setSearchText(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleDrawer = (isOpen) => {
    setIsDrawerOpen(isOpen);
  };

  return (
    <Box className="border-[1px] rounded-lg w-full">
      <Box className="flex justify-between  p-6">
        <Typography variant="h6" className="!font-semibold" gutterBottom>
          Wallets
        </Typography>
        <Box className="flex gap-2">
          <TextField
            className="w-96"
            placeholder="Search Wallets"
            type="search"
            size="small"
            onChange={handleChangeSearchText}
            value={searchText}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchOutlined />
                </IconButton>
              ),
            }}
          />
          <CommonButton
            text="Filters"
            bgColor="white"
            onClick={() => handleDrawer(true)}
            StartIcon={<FilterAlt className="black" />}
          />
        </Box>
      </Box>
      <div className="overflow-x-auto">
        <CommonTable
          headers={tableHeaders}
          data={walletData}
          renderRow={renderRow}
          handleRowClick={handleRowClick}
        />
      </div>
      <div className="mt-2 flex justify-between p-6 w-full">
        <CommonButton
          text="Previous"
          disabled={page === 1}
          onClick={handlePrevious}
          StartIcon={<ArrowBackIos fontSize="small" />}
        />
        <CustomPagination
          count={Math.ceil(totalPages / 10)}
          page={page}
          onChange={handlePageChange}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          shape="rounded"
        />
        <CommonButton
          text="Next"
          disabled={page === Math.ceil(totalPages / 10)}
          onClick={handleNext}
          EndIcon={<ArrowForwardIos fontSize="small" />}
        />
      </div>
      <FiltersDrawer open={isDrawerOpen} onClose={() => handleDrawer(false)}>
        <WalletListFilters
          setFilters={setFilters}
          setPage={setPage}
          handleDrawer={handleDrawer}
        />
      </FiltersDrawer>
    </Box>
  );
};

export default WalletList;
