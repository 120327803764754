import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import CustomTabPanel from "../../../common/CustomTabPanel.js";
import TransactionsTab from "./Transactions/TransactionsTab.js";
import GlobalCryptoTab from "./Crypto/GlobalCryptoTab.js";

const ApplicationTabs = ({ tabValue, handleChange, params }) => {
  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  });

  return (
    <Box className="w-full overflow-y-scroll">
      <Tabs
        className="pt-4 border-b-[2px] mx-4"
        value={tabValue}
        onChange={handleChange}
        TabIndicatorProps={{ style: { backgroundColor: "black" } }}
      >
        <Tab
          label="Crypto"
          sx={{
            "&.Mui-selected": {
              color: "black",
              fontSize: "16px",
            },
            "&.MuiTab-root": {
              textTransform: "unset !important",
              fontSize: "16px",
            },
          }}
          {...a11yProps(0)}
        />
        <Tab
          label="Transactions"
          sx={{
            "&.Mui-selected": {
              color: "black",
            },
            "&.MuiTab-root": {
              textTransform: "unset !important",
              fontSize: "16px",
            },
          }}
          {...a11yProps(1)}
        />
      </Tabs>
      <CustomTabPanel value={tabValue} index={0}>
        {/* <CryptoTab applicationId={params?.id} /> */}
        <GlobalCryptoTab />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <TransactionsTab applicationId={params?.id} />
      </CustomTabPanel>
    </Box>
  );
};

export default ApplicationTabs;
