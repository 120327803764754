import React, { useState, useEffect, useCallback } from "react";
import { Box, TableCell, Snackbar } from "@mui/material";
import IOSSwitch from "../../../../../common/IOSSwitch.js";
import apiRequest from "../../../../../../utils/api.js";
import CommonTable from "../../../../../common/CommonTable.js";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const CryptoTab = (props) => {
  const [rows, setRows] = useState();
  const [openToast, setOpenToast] = useState("");
  const { id } = useParams();

  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  const getEnabledCrypto = useCallback(async () => {
    const res = await apiRequest("/list-enabled-crypto", "POST", {
      applicationId: id,
    });

    if (res?.success) {
      try {
        setRows(res.data);
      } catch (err) {
        console.error(err);
      }
    } else {
      setOpenToast("Something went wrong");
    }
  }, [id]);

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast("");
  };

  const handleEnabledChange = async (rowId) => {
    const newData = rows.find((item) => item._id === rowId);
    const body = {
      enabled: !newData?.enabled,
      cryptoId: newData?._id,
      applicationId: id,
    };
    const res = await apiRequest("/update-application-data", "POST", body);
    if (res?.success) {
      try {
        getEnabledCrypto();
        setOpenToast("Crypto status updated");
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    getEnabledCrypto();
  }, [getEnabledCrypto]);

  const tableHeaders = ["Asset", "Enabled"];

  const renderRow = (row) => {
    return (
      <>
        <TableCell className="w-60">
          <Box className="flex items-center gap-2">
            <img
              src={`${imageBaseURL}/images${row?.icon}`}
              className="rounded-full w-12 h-12"
              alt="icon"
            />
            <p className="text-gray-500">{row?.symbol}</p>
          </Box>
        </TableCell>
        <TableCell>
          {" "}
          <IOSSwitch
            sx={{ m: 1 }}
            checked={row?.enabled}
            onChange={() => handleEnabledChange(row?._id)}
          />
        </TableCell>
      </>
    );
  };

  return (
    <Box className="mt-10">
      <Box className="flex justify-between px-6 mb-6">
        <p className="font-semibold text-xl">Crypto</p>
      </Box>
      <Box>
        <CommonTable headers={tableHeaders} data={rows} renderRow={renderRow} />
        <Snackbar
          open={openToast ? true : false}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          message={openToast}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
      </Box>
    </Box>
  );
};

export default CryptoTab;
