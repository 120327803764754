import { SET_APPLICATION } from "../actions/actionTypes.js";

const globalSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_APPLICATION:
      return {
        ...state,
        application: {...action.payload},
      };
    default:
      return state;
  }
};

export default globalSettingsReducer;
