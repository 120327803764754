import { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import "./styles/datePicker.css";

const CommonDatePicker = (props) => {
  const { setDateRange } = props;

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleChange = (date) => {
    setValue(date);
    setDateRange(date?.startDate, date?.endDate);
  };

  return (
    <div className="border-[1px] rounded-md">
      <Datepicker value={value} onChange={handleChange} />
    </div>
  );
};

export default CommonDatePicker;
