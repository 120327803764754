import { Done, Close, Warning } from "@mui/icons-material";

export const getStatusComponent = (status) => {
  switch (status) {
    case "CAASBuyFlow:BuyCompleted":
    case "CAASSellFlow:SellCompleted":
    case "CAASReceiveFlow:ReceiveCompleted":
    case "CAASExchangeFlow:ExchangeCompleted":
    case "CAASSendFlow:SendCompleted":
      return (
        <div className="flex gap-1">
          <div className="bg-[#ECFDF3] lg:py-1 py-px lg:px-3 px-2 rounded-2xl text-center lg:text-sm text-[13px]  font-medium text-[#027A48]  ">
            Completed
          </div>
          <Done fontSize="small" className="text-[#027A48]" />
        </div>
      );
    case "CAASSendFlow:ErrorOccured":
    case "CAASExchangeFlow:ErrorOccured":
    case "CAASReceiveFlow:ErrorEncountered":
    case "CAASSellFlow:ErrorOccured":
    case "CAASBuyFlow:ErrorOccured":
      return (
        <div className="flex gap-1">
          <div className="bg-[#FEF3F2] lg:py-1 py-px lg:px-3 px-2 rounded-2xl text-center lg:text-sm font-medium text-[#B42318] ">
            Failed
          </div>
          <Close fontSize="small" className="text-[#B42318]" />
        </div>
      );
    case "CAASReceiveFlow:ReceiveRejected":
      return (
        <div className="flex gap-1">
          <div className="bg-[#ECFDF3] lg:py-1 py-px lg:px-3 px-2 rounded-2xl text-center lg:text-sm font-medium text-[#B54708] ">
            Rejected
          </div>
          <Warning fontSize="small" className="text-[#B54708]" />
        </div>
      );
    case "CAASReceiveFlow:PendingManualApproval":
      return (
        <div className="bg-[#F4F4F5] lg:py-1 py-px lg:px-3 px-2 rounded-2xl text-center lg:text-sm font-medium ">
          Pending
        </div>
      );
    default:
      return (
        <div className="bg-[#EFF8FF] lg:py-1 py-px lg:px-3 px-2 rounded-2xl text-center lg:text-sm font-medium text-[#175CD3]">
          Processing
        </div>
      );
  }
};

export const getCryptoImage = (data, imageBaseURL) => {
  switch (data?.crypto || data?.symbol) {
    case "BTC":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/bitcoin.png`}
          className="rounded-full w-10 h-10"
          alt="icon"
        />
      );
    case "ETH":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/eth.png`}
          className="rounded-full w-10 h-10"
          alt="icon"
        />
      );
    case "LTC":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/itc.png`}
          className="rounded-full w-10 h-10"
          alt="icon"
        />
      );
    case "XRP":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/xrp.png`}
          className="rounded-full w-10 h-10"
          alt="icon"
        />
      );
    case "TRX":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/trx.png`}
          className="rounded-full w-10 h-10"
          alt="icon"
        />
      );
    case "DAI":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/dai.png`}
          className="rounded-full w-10 h-10"
          alt="icon"
        />
      );
    case "USDT":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/usdt.png`}
          className="rounded-full w-10 h-10"
          alt="icon"
        />
      );
    case "USDC":
      return (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/usdc.png`}
          className="rounded-full w-10 h-10"
          alt="icon"
        />
      );  
    default:
      return <img src={``} className="rounded-full w-10 h-10" alt="icon" />;
  }
};
