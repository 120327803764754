import React, { useEffect, useState, useCallback } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { GridViewOutlined, SyncAlt } from "@mui/icons-material";
import {
  Box,
  Typography,
  Link,
  Breadcrumbs,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import apiRequest from "../../utils/api.js";
import CustomTabPanel from "../common/CustomTabPanel.js";
import TransactionInfoTab from "./tabs/TransactionInfoTab.js";
import EventLogTab from "./tabs/EventLogTab.js";
import CommonButton from "../common/CommonButton.js";
import SendNotificationModal from "./SendNotificationModal.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TransactionDetails = () => {
  const [transactionData, setTransactionData] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [opennotificationStatus, setOpenNotificationStatus] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const params = useParams();

  const openSendNotificationModal = () => {
    setOpenNotificationStatus(!opennotificationStatus);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to="/"
    >
      <GridViewOutlined className="mr-3" />
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      component={RouterLink}
      to="/transactions"
    >
      <SyncAlt className="mx-3" />
      <span className="mr-4">Transactions</span>
    </Link>,
    <Typography key="3" color="text.primary">
      <span className="ml-3">{transactionData?.type}</span>
    </Typography>,
  ];

  const getTransaction = useCallback(async () => {
    const res = await apiRequest("/get-transactions", "POST", {
      transactionId: params.id,
    });
    if (res?.success) {
      try {
        setTransactionData(res?.data?.transactionsList[0]);
      } catch (err) {
        console.error(err);
      }
    }
  }, [params.id]);

  const handleApproveForProcessing = async () => {
    try {
      const historyData = transactionData?.status?.history.find((item) =>
        item.code.includes("CreateTransaction")
      );

      if (historyData?.message) {
        const messageData = JSON.parse(historyData.message);

        const quotationPrice = messageData?.quotationPrice;
        let fiatOrFromCrypto;

        if (transactionData?.type?.toLowerCase() === "buy") {
          fiatOrFromCrypto = messageData?.fromCrypto;
        } else {
          fiatOrFromCrypto = messageData?.fiat;
        }

        if (quotationPrice && fiatOrFromCrypto) {
          const res = await apiRequest("/approve-transaction", "POST", {
            transactionId: params.id,
            quotationPrice,
            fiat: fiatOrFromCrypto,
          });

          if (res?.success) {
            setTransactionData(res?.data?.transactionsList[0]);
          }
        } else {
          console.error(
            "Failed to extract quotationPrice or fiat/fromCrypto from the transaction message."
          );
        }
      } else {
        console.error("No historyData found for 'CreateTransaction'");
      }
    } catch (err) {
      console.error("An error occurred during the process:", err);
    }
  };

  const isButtonDisabled = (transactionData) => {
    if (!transactionData) return true;

    const validTypes = ["exchange", "buy", "sell"];
    const validStatusCodes = [
      "caasbuyflow:createtransaction",
      "caasexchangeflow:createtransaction",
      "caassellflow:createtransaction",
    ];

    const pendingApprovalStatuses = [
      "caasbuyflow:pendingmanualapproval",
      "caasexchangeflow:pendingmanualapproval",
      "caassellflow:pendingmanualapproval",
    ];

    const transactionType = transactionData?.type?.toLowerCase();
    const transactionStatusCodeHistory = transactionData?.status?.history?.map(
      (item) => item.code.toLowerCase()
    );

    const hasValidType = validTypes.includes(transactionType);

    const hasValidStatusCode = transactionStatusCodeHistory?.some((code) =>
      validStatusCodes.includes(code)
    );

    const isPendingManualApproval = pendingApprovalStatuses.includes(
      transactionData?.status?.code?.toLowerCase()
    );

    return hasValidType && hasValidStatusCode && !isPendingManualApproval;
  };

  useEffect(() => {
    getTransaction();
  }, [getTransaction]);

  return (
    <Box>
      <div className="flex justify-between items-center ">
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
        <div className="flex items-center flex-row gap-4">
          <CommonButton
            text="Approve for Processing"
            bgColor="black"
            onClick={handleClickOpen}
            disabled={isButtonDisabled(transactionData)}
          />
          <CommonButton
            text="Send Notification"
            bgColor="white"
            onClick={openSendNotificationModal}
          />
        </div>
      </div>
      {opennotificationStatus && (
        <SendNotificationModal
          open={opennotificationStatus}
          close={openSendNotificationModal}
        />
      )}
      <Box className="mt-6 border-[1px] rounded-xl">
        <div className="flex justify-between items-center">
          <p className="font-semibold text-xl p-6">Transaction Details</p>
        </div>
        <Box className="">
          <Tabs
            className="border-b-[2px] mt-4 mx-6"
            value={tabValue}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: "black",
              },
            }}
          >
            <Tab
              label="Transaction Info"
              sx={{
                "&.Mui-selected": {
                  color: "black",
                  fontSize: "16px",
                },
                "&.MuiTab-root": {
                  textTransform: "unset !important",
                  fontSize: "16px",
                  padding: "0",
                  marginRight: "14px",
                },
              }}
              {...a11yProps(0)}
            />
            <Tab
              label="Event Log"
              sx={{
                "&.Mui-selected": {
                  color: "black",
                },
                "&.MuiTab-root": {
                  textTransform: "unset !important",
                  fontSize: "16px",
                  padding: "0",
                  marginRight: "10px",
                },
              }}
              {...a11yProps(1)}
            />
          </Tabs>
          <CustomTabPanel value={tabValue} index={0}>
            <TransactionInfoTab data={transactionData} />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <EventLogTab data={transactionData?.status?.history} />
          </CustomTabPanel>
        </Box>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Are you sure about your action ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please be aware that this action cannot be undone once you agree.
            Are you sure you want to proceed ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleApproveForProcessing}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TransactionDetails;
