import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { GridViewOutlined } from "@mui/icons-material";

const ApplicationBreadcrumbs = ({ applicationName }) => {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <Link underline="hover" key="1" color="inherit" component={RouterLink} to="/">
        <GridViewOutlined />
      </Link>
      <Link underline="hover" key="2" color="inherit" component={RouterLink} to="/global-settings">
        Global Settings
      </Link>
      <Typography key="3" color="text.primary">
        {applicationName}
      </Typography>
    </Breadcrumbs>
  );
};

export default ApplicationBreadcrumbs;
