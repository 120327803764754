import React from "react";
import { Modal, TextField } from "@mui/material";
import {
  CloseOutlined,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material/index.js";
import { VisibilityOff as VisibilityOffIcon } from "@mui/icons-material/index.js";
import { IconButton, InputAdornment } from "@mui/material";
import apiRequest from "../../../../../utils/api.js";
import CommonButton from "../../../../common/CommonButton.js";

const AddApplication = (props) => {
  const [textInput, setTextInput] = React.useState({
    name: "",
    email: "",
    password: "",
    mailToNotify: "",
    notificationURL: "",
  });
  const [isPasswordVisible, setIsPasswordVissible] = React.useState(false);
  const EndAdorment = ({ isPasswordVisible, setIsPasswordVissible }) => {
    return (
      <InputAdornment position="end">
        <IconButton onClick={() => setIsPasswordVissible(!isPasswordVisible)}>
          {isPasswordVisible ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
        </IconButton>
      </InputAdornment>
    );
  };

  const handleChange = (e) => {
    setTextInput({ ...textInput, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const accessToken = localStorage.getItem("token");
    const body = {
      token: accessToken,
      name: textInput.name,
      email: textInput?.email,
      password: textInput.password,
      mailToNotify: textInput.mailToNotify,
      notificationURL: textInput.notificationURL,
    };
    const res = await apiRequest("/save-application", "POST", body);
    if (res.success) {
      props.close();
    }
  };
  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      className="flex justify-center items-center"
    >
      <div className="flex  bg-white p-4 px-8 h-max rounded-xl edit-balance">
        <form onSubmit={handleFormSubmit} className="flex flex-col gap-10">
          <div className="flex justify-between">
            <p className="text-2xl text-zinc-900 font-semibold">
              Add Application
            </p>
            <CloseOutlined onClick={props.close} className="cursor-pointer" />
          </div>

          <div className="flex justify-between">
            <p className="font-normal text-base text-zinc-800">Name:</p>
            <TextField
              className="text-field"
              type="text"
              name="name"
              value={textInput.name}
              onChange={(e) => handleChange(e)}
              placeholder="Enter account name"
              variant="outlined"
              size="small"
              required
            />
          </div>
          <div className="flex justify-between">
            <p className="font-normal text-base text-zinc-800">Email:</p>
            <TextField
              className="text-field"
              type="email"
              name="email"
              value={textInput?.email}
              onChange={(e) => handleChange(e)}
              placeholder="Enter your email"
              variant="outlined"
              size="small"
              required
            />
          </div>
          <div className="flex justify-between">
            <p className="font-normal text-base text-zinc-800">Password:</p>
            <TextField
              className="text-field"
              name="password"
              value={textInput.password}
              onChange={(e) => handleChange(e)}
              placeholder="Create password"
              type={isPasswordVisible ? "text" : "password"}
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <EndAdorment
                    isPasswordVisible={isPasswordVisible}
                    setIsPasswordVissible={setIsPasswordVissible}
                  />
                ),
              }}
              required
            />
          </div>
          <div className="flex justify-between">
            <p className="font-normal text-base text-zinc-800">
              Notification Mail:
            </p>
            <TextField
              className="text-field"
              placeholder="Enter notification mail"
              type="text"
              name="mailToNotify"
              value={textInput.mailToNotify}
              onChange={(e) => handleChange(e)}
              variant="outlined"
              size="small"
              required
            />
          </div>
          <div className="flex gap-8 justify-between">
            <p className="font-normal text-base text-zinc-800">
              Notification URL:
            </p>
            <TextField
              className="text-field"
              placeholder="Enter notification url"
              type="text"
              name="notificationURL"
              value={textInput.notificationURL}
              onChange={(e) => handleChange(e)}
              variant="outlined"
              size="small"
              required
            />
          </div>
          <div className="flex justify-end">
            <CommonButton
              text="Send invite"
              bgColor="black"
              width="w-36"
              borderRadius="rounded-3xl"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddApplication;
