import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles/index.js";
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";
import { Box, Typography, IconButton, TextField } from "@mui/material";
import apiRequest from "../../utils/api.js";
import moment from "moment";
import { Pagination } from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Download,
  FilterAlt,
  SearchOutlined,
  Settings,
} from "@mui/icons-material";
import CommonTable from "../common/CommonTable.js";
import { useNavigate } from "react-router-dom";
import { getStatusComponent } from "../../utils/common.js";
import CommonButton from "../common/CommonButton.js";
import FiltersDrawer from "../common/FiltersDrawer.js";
import TransactionListFilters from "./TransactionListFilters.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F4F4F5",
    color: "#51525C",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const CustomPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-previousNext": {
    display: "none",
  },
}));

const TransactionList = () => {
  const [transactionData, setTransactionData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filters, setFilters] = useState({});

  const handleDrawer = (isOpen) => {
    setIsDrawerOpen(isOpen);
  };

  const handleChangeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText.length >= 3 || searchText === "") {
      setPage(1);
      const handler = setTimeout(() => {
        setDebouncedSearch(searchText);
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [searchText]);

  const fetchTransactionListData = useCallback(
    async (searchText) => {
      const accessToken = localStorage.getItem("token");

      const res = await apiRequest("/get-transactions", "POST", {
        token: accessToken,
        page: page,
        searchText: debouncedSearch,
        ...filters,
      });
      if (res?.success) {
        setTransactionData(res.data.transactionsList);
        setTotalPages(res?.data?.totalCount);
      }
    },
    [page, debouncedSearch, filters]
  );

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page < Math.ceil(totalPages / 10)) {
      setPage(page + 1);
    }
  };

  const tableHeaders = [
    "Transaction Id",
    "Type",
    "Blockchain",
    "Network",
    "Status Code",
    "Crypto Amount",
    "Fiat Amount",
    "Created Date",
    "Application Name",
    "Application Id",
    "Trade Order Id",
    "Crypto Currency",
    "Fiat Currency",
  ];
  const renderRow = (row) => {
    return (
      <>
        <StyledTableCell component="th" scope="row" className="!max-w-72">
          {row?.transactionId
            ? row?.transactionId.substring(0, 6) +
              "..." +
              row?.transactionId.substring(row?.transactionId.length - 6)
            : "NA"}
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          <p className="text-[#1570EF]">{row?.type ? row?.type : "NA"}</p>
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.blockchain ? row?.blockchain : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.network ? row?.network : "NA"}
        </StyledTableCell>
        <StyledTableCell align="center">
          {row?.status?.code ? getStatusComponent(row?.status?.code) : "N/A"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.cryptoAmount ? row?.cryptoAmount : "N/A"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.fiatAmount ? row?.fiatAmount : "N/A"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {moment(row?.createdDate).format("DD/MM/YYYY") || "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.application?.name ? row?.application?.name : "N/A"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.application?.applicationId
            ? row?.application?.applicationId
            : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.autoTrade?.tradeOrderId
            ? row?.autoTrade?.tradeOrderId.substring(0, 6) +
              "..." +
              row?.autoTrade?.tradeOrderId.substring(
                row?.autoTrade?.tradeOrderId.length - 6
              )
            : "N/A"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.cryptoSymbol ? row?.cryptoSymbol : "N/A"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.depositFiatCurrency ? row?.depositFiatCurrency : "N/A"}
        </StyledTableCell>
      </>
    );
  };

  const handleRowClick = (row) => {
    navigate(`/transactions/${row.transactionId}`);
  };

  useEffect(
    (debouncedSearch) => {
      if (debouncedSearch) {
        fetchTransactionListData();
      } else {
        fetchTransactionListData();
      }
    },
    [page, fetchTransactionListData, debouncedSearch]
  );
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box className="border-[1px] rounded-lg w-full transaction-list">
      <div className="flex justify-between items-center">
        <div>
          <Typography variant="h6" className="!font-semibold p-6" gutterBottom>
            Transactions History
          </Typography>
        </div>
        <div className="flex gap-2">
          <TextField
            className="w-96"
            placeholder="Search Transactions"
            type="search"
            size="small"
            onChange={handleChangeSearchText}
            value={searchText}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchOutlined />
                </IconButton>
              ),
            }}
          />

          <IconButton>
            <Download className="bg-slate-100" />
          </IconButton>
          <CommonButton
            text="Filter"
            bgColor="white"
            onClick={() => handleDrawer(true)}
            StartIcon={<FilterAlt className="black" />}
          />
          <IconButton>
            <Settings className="bg-slate-100" />
          </IconButton>
        </div>
      </div>
      <div className="overflow-x-auto">
        <CommonTable
          headers={tableHeaders}
          data={transactionData}
          renderRow={renderRow}
          handleRowClick={handleRowClick}
        />
      </div>
      <div className="mt-2 flex justify-between p-6 w-full">
        <CommonButton
          text="Previous"
          disabled={page === 1}
          onClick={handlePrevious}
          StartIcon={<ArrowBackIos fontSize="small" />}
        />
        <CustomPagination
          count={Math.ceil(totalPages / 10)}
          page={page}
          onChange={handlePageChange}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          shape="rounded"
        />
        <CommonButton
          text="Next"
          disabled={page === Math.ceil(totalPages / 10)}
          onClick={handleNext}
          EndIcon={<ArrowForwardIos fontSize="small" />}
        />
      </div>
      <FiltersDrawer open={isDrawerOpen} onClose={() => handleDrawer(false)}>
        <TransactionListFilters
          setFilters={setFilters}
          setPage={setPage}
          handleDrawer={handleDrawer}
        />
      </FiltersDrawer>
    </Box>
  );
};

export default TransactionList;
