import React, { useState, useEffect } from "react";
import { Box, TableCell, Snackbar } from "@mui/material";
import CommonTable from "../../../../CommonTable.js";
import apiRequest from "../../../../../../utils/api.js";
import useDebounce from "../../../../../../customHook/useDebounce.js";
import { useSelector } from "react-redux";
import { getCryptoImage } from "../../../../../../utils/common.js";

const FeesTabs = (props) => {
  const { transactionType, data, getFeeSettings } = props;
  const [feeProfileType, setFeeProfileType] = useState("standard");
  const [feeData, setFeeData] = useState([]);
  const [openToast, setOpenToast] = useState("");

  const application = useSelector((state) => state.globalSettings.application?.data);
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  const type = [
    { label: "Standard", key: "standard" },
    { label: "VIP", key: "vip" },
    { label: "Super VIP", key: "supervip" },
  ];
  const handlefeeProfileType = (item) => {
    setFeeProfileType(item.key);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast("");
  };

  const headers = [{ label: "Currency" }, { label: "Fee's" }];

  useEffect(() => {
    let tableData;
    switch (transactionType) {
      case "Buy":
        tableData = data?.buy;
        break;
      case "Exchange":
        tableData = data?.exchange;
        break;
      case "Receive":
        tableData = data?.receive;
        break;
      case "Sell":
        tableData = data?.sell;
        break;
      case "Send":
        tableData = data?.send;
        break;
      default:
        tableData = [];
    }
    tableData = tableData?.[feeProfileType] || [];
    setFeeData(tableData);
  }, [transactionType, feeProfileType, data]);

  const updateFee = async (row, fee) => {
    let profile;
    switch (feeProfileType) {
      case "standard":
        profile = "Standard";
        break;
      case "vip":
        profile = "VIP";
        break;
      default:
        profile = "Super VIP";
        break;
    }

    const res = await apiRequest(
      "/create-or-update-cypto-fee-setting",
      "POST",
      {
        applicationId: application?._id,
        profile,
        transactionType: transactionType,
        fee,
        crypto: row?.crypto || row?.symbol,
      }
    );

    if (res?.success) {
      getFeeSettings();
      setOpenToast("Fee setting updated successfully");
    } else {
      setOpenToast(res?.error);
    }
  };

  const debouncedUpdateFee = useDebounce((row, fee) => {
    updateFee(row, fee);
  }, 500);

  const handleFeeChange = async (e, row) => {
    const updatedFeeData = feeData?.map((item) =>
      item?._id === row?._id ? { ...item, fee: e.target.value } : item
    );
    setFeeData(updatedFeeData);
    debouncedUpdateFee(row, e.target.value);
  };

  const renderRow = (row, index) => {
    return (
      <>
        <TableCell component="th" scope="row" className="w-60">
          <Box className="flex items-center gap-4">
            {getCryptoImage(row,imageBaseURL)}
            <p className="text-gray-900">
              {row?.symbol ? row?.symbol : ""}
              {row?.crypto ? row?.crypto : ""}
            </p>
          </Box>
        </TableCell>
        <TableCell>
          <Box className="flex">
            <input
              type="Number"
              className="border-l-2 border-y-2 rounded-l-lg pl-4 py-2 w-24"
              value={row?.fee || "0"}
              onChange={(e) => handleFeeChange(e, row)}
            />
            <Box className="border-r-2 border-y-2 self-center py-2 pr-8 rounded-r-lg">
              %
            </Box>
          </Box>
        </TableCell>
      </>
    );
  };

  return (
    <Box>
      <Box className="flex justify-between">
        <Box className="flex gap-[8px] ml-6 mb-6">
          {type.map((item) => (
            <Box
              key={item.key}
              className={`p-[8px] flex items-center justify-center cursor-pointer hover:bg-[#E4E4E7] hover:rounded-lg ${
                feeProfileType === item.key
                  ? "border-b-2 border-primary bg-gray-100 rounded-lg border-b-[#18181B]"
                  : ""
              }`}
              onClick={() => handlefeeProfileType(item)}
            >
              {item.label}
            </Box>
          ))}
        </Box>
        <Box>{/* <Button>Button</Button> */}</Box>
      </Box>
      <CommonTable headers={headers} renderRow={renderRow} data={feeData} />
      <Snackbar
        open={openToast ? true : false}
        autoHideDuration={3000}
        onClose={handleCloseToast}
        message={openToast}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
    </Box>
  );
};

export default FeesTabs;
