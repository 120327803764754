import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Pagination,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";

import CommonTable from "../common/CommonTable.js";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Download,
  DriveFileRenameOutlineTwoTone,
  FilterAlt,
  GridViewOutlined,
  SearchOutlined,
  Settings,
  WalletOutlined,
} from "@mui/icons-material";
import { useParams, Link as RouterLink } from "react-router-dom";
import CopyButton from "../common/CopyButton.js";
import apiRequest from "../../utils/api.js";
import DropDown from "../common/DropDown.js";
import moment from "moment";
import "../Wallets/Wallet.css";
import EditBalance from "./EditBalance.js";
import Transfer from "./Transfer.js";
import CommonButton from "../common/CommonButton.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const CustomPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-previousNext": {
    display: "none",
  },
}));

const WalletDetails = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [walletData, setWalletData] = useState({
    balance: [],
    balanceHistory: [],
    balanceAmountUSDApprox: [],
    walletDetailObj: {},
  });
  const [searchText, setSearchText] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [openEditBalance, setOpenEditBalance] = useState(false);
  const [openTransfer, setOpenTransfer] = useState(false);

  const openTransferModal = () => {
    setOpenTransfer(!openTransfer);
  };

  const openEditBalanceModal = () => {
    setOpenEditBalance(!openEditBalance);
    fetchWalletDetailsData();
  };

  const params = useParams();
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to="/"
    >
      <GridViewOutlined className="mr-3" />
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      component={RouterLink}
      to="/wallets"
    >
      <WalletOutlined className="mx-3" />
      <span className="mr-4">Wallets</span>
    </Link>,
    <Typography key="3" color="text.primary">
      <span className="ml-3">
        Wallet Address: {params?.addressKey}
        <CopyButton textToCopy={params?.addressKey} />
      </span>
    </Typography>,
  ];

  const handleChangeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const fetchWalletDetailsData = useCallback(async () => {
    const res = await apiRequest("/get-transaction-by-wallet", "POST", {
      addressKey: params.addressKey,
      applicationId: params._id,
      page: page,
      searchText: debouncedSearch,
    });
    if (res.success) {
      setWalletData({
        balance: res?.data[0]?.walletDetails?.balance,
        balanceHistory: res?.data[0]?.walletDetails?.balanceHistory,
        balanceAmountUSDApprox:
          res?.data[0]?.walletDetails?.balance[0]?.balanceAmountUSDApprox,
        walletDetailObj: res?.data[0]?.walletDetails,
      });
      setTotalPages(res?.data[0]?.walletDetails?.balanceHistory?.totalCount);
    }
  }, [page, params._id, params.addressKey, debouncedSearch]);

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page < Math.ceil(totalPages / 10)) {
      setPage(page + 1);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const tableHeaders = [
    "Date",
    "Transaction Type",
    "Transaction ID",
    "Asset",
    "Amount",
    "Previous Balance",
    "Current Balance",
  ];

  const tableWalletInfoHeaders = [
    "Token",
    "Balance Amount",
    "Last Updated",
    "",
  ];

  const renderWalletInfoRow = (row) => {
    return (
      <>
        <StyledTableCell>
          <Box className="flex items-center gap-2">
            {row?.cryptoName ? row?.cryptoName : "NA"}
          </Box>
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.balanceAmount ? row?.balanceAmount : "NA"}
          <DriveFileRenameOutlineTwoTone
            className="m-4"
            fontSize="small"
            color="disabled"
            BorderColor="solid black"
            onClick={openEditBalanceModal}
          />

          {openEditBalance && (
            <EditBalance
              open={openEditBalance}
              close={openEditBalanceModal}
              currentBalance={row?.balanceAmount}
              cryptoSymbol={row?.cryptoSymbol}
              walletId={walletData?.walletDetailObj?._id}
              newBalanceAmountUSDApprox={walletData?.balanceAmountUSDApprox}
            />
          )}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.updatedDate
            ? moment(row?.updatedDate).format("DD/MM/YYYY - HH:mm")
            : "NA"}
        </StyledTableCell>
        <StyledTableCell align="right" className="inline-flex">
          <div className="flex justify-end">
            <CommonButton
              text="Transfer"
              size="small"
              width="w-20"
              onClick={openTransferModal}
            />
          </div>
        </StyledTableCell>
      </>
    );
  };

  const renderRow = (row) => {
    return (
      <>
        <StyledTableCell align="left">
          {row?.updatedDate
            ? moment(row?.updatedDate).format("DD/MM/YYYY - HH:mm")
            : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row.transactionType ? row.transactionType : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row.transactionId ? row.transactionId : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row.cryptoSymbol ? row.cryptoSymbol : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row.amount ? row.amount : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row.previousBalance ? row.previousBalance : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row.currentBalance ? row.currentBalance : "NA"}
        </StyledTableCell>
      </>
    );
  };

  useEffect(() => {
    if (searchText.length >= 3 || searchText === "") {
      setPage(1);
      const handler = setTimeout(() => {
        setDebouncedSearch(searchText);
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [searchText]);

  useEffect(
    (debouncedSearch) => {
      if (debouncedSearch) {
        fetchWalletDetailsData();
      } else {
        fetchWalletDetailsData();
      }
    },
    [page, fetchWalletDetailsData, debouncedSearch]
  );

  return (
    <Box className="flex flex-col mb-4 gap-4">
      <box>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </box>
      <Box className="border-[1px] rounded-lg w-full wallet-details">
        <div className="flex justify-between p-6">
          <div>
            <p className="font-semibold">
              Ethereum wallet{" "}
              <DropDown options={["Full", "Available", "In Use", "Locked"]} />
            </p>
            {params.addressKey} <CopyButton textToCopy={params?.addressKey} />
          </div>
          <div className="flex gap-2 items-center">
            <CommonButton text="View on blockchain" bgColor="black" />
            <CommonButton
              text="Transfer"
              background="bg-gray-100"
              width="w-20"
              onClick={openTransferModal}
            />
          </div>
        </div>
        <Box>
          <CommonTable
            headers={tableWalletInfoHeaders}
            data={walletData.balance}
            renderRow={renderWalletInfoRow}
            approxUSDBalance={walletData.balanceAmountUSDApprox}
          />
        </Box>
        <Box className="flex  flex-col justify-between p-6">
          <div>
            <p className="font-semibold ">All Transactions</p>
          </div>
          <div className="flex justify-between">
            <div className="flex py-6">
              <TextField
                placeholder="Search transactions"
                type="search"
                size="small"
                onChange={handleChangeSearchText}
                value={searchText}
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchOutlined />
                    </IconButton>
                  ),
                }}
              />
            </div>
            <div className="items-center flex justify-center gap-2">
              <IconButton>
                <Download className="bg-slate-100 h-10 w-10" />
              </IconButton>
              <CommonButton
                text="Filter"
                bgColor="white"
                StartIcon={<FilterAlt className="black" />}
              />
              <IconButton>
                <Settings className="bg-slate-100 h-10 w-10" />
              </IconButton>
            </div>
          </div>
        </Box>
        <Box>
          <CommonTable
            headers={tableHeaders}
            data={walletData.balanceHistory}
            renderRow={renderRow}
          />

          <Transfer open={openTransfer} close={openTransferModal} />
        </Box>
        <div className="mt-2 flex justify-between p-6 w-full">
          <CommonButton
            text="Previous"
            disabled={page === 1}
            onClick={handlePrevious}
            StartIcon={<ArrowBackIos fontSize="small" />}
          />
          <CustomPagination
            count={Math.ceil(totalPages / 10)}
            page={page}
            onChange={handlePageChange}
            siblingCount={1}
            boundaryCount={1}
            variant="outlined"
            shape="rounded"
          />
          <CommonButton
            text="Next"
            disabled={page === Math.ceil(totalPages / 10)}
            onClick={handleNext}
            EndIcon={<ArrowForwardIos fontSize="small" />}
          />
        </div>
      </Box>
    </Box>
  );
};
export default WalletDetails;
