import { CloseOutlined } from "@mui/icons-material";
import { Modal, TextField } from "@mui/material";
import React, { useState } from "react";
import apiRequest from "../../utils/api.js";
import CommonButton from "../common/CommonButton.js";

const EditBalance = (props) => {
  const [textInput, setTextInput] = useState({
    newBalanceAmount: null,
    note: "",
  });
  const handleChange = (e) => {
    setTextInput({ ...textInput, [e.target.name]: e.target.value });
  };

  const exchangeRate = async () => {
    const body = {
      fiatList: ["USD"],
      cryptoAmount: textInput.newBalanceAmount,
      cryptoCurrency: props.cryptoSymbol,
      exchangeConversionType: "crypto-to-fiat",
    };
    const res = await apiRequest("/get-exchange-rate-list", "POST", body);
    return res?.data?.fiatAmounts?.USD;
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedUSD = await exchangeRate();

    if (updatedUSD) {
      const body = {
        currentBalance: props.currentBalance,
        cryptoSymbol: props.cryptoSymbol,
        walletId: props.walletId,
        newBalanceAmountUSDApprox: updatedUSD,
        newBalanceAmount: textInput.newBalanceAmount,
        note: textInput.note,
      };
      await apiRequest("/update-wallet-crypto-balance", "POST", body);
      props.close();
    }
  };

  return (
    <Modal open={props.open} className="flex justify-center items-center">
      <div className="flex  bg-slate-50 p-4 px-8 h-max rounded-xl edit-balance">
        <form className="flex flex-col gap-10" onSubmit={handleFormSubmit}>
          <div className="flex justify-between">
            <p className="text-2xl text-gray-900 font-semibold">Edit Balance</p>
            <CloseOutlined onClick={props.close} className="cursor-pointer" />
          </div>
          <div className="flex justify-between gap-6 items-center">
            <p className="font-medium  text-slate-700">Current balance:</p>
            <span className="border-2 flex items-center pl-4 bg-zinc-100 fixed-value">
              {props.currentBalance}
            </span>
          </div>
          <div className="flex gap-12 items-center">
            <p>New balance:</p>
            <TextField
              variant="outlined"
              className="text-field"
              type="number"
              placeholder="Enter new balance"
              value={textInput.newBalanceAmount}
              name="newBalanceAmount"
              size="small"
              required
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div>
            <p>Note:</p>
            <TextField
              type="text"
              placeholder="Add your note..."
              variant="outlined"
              value={textInput.note}
              name="note"
              rows={6}
              onChange={(e) => handleChange(e)}
              fullWidth
              multiline
              className="bg-zinc-100 textarea-field"
            />
          </div>
          <div className="flex justify-end ">
            <CommonButton
              text="Save"
              bgColor="black"
              width="w-24"
              borderRadius="rounded-3xl"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default EditBalance;