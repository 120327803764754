import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/rootReducer.js";

const configureAppStore = () => {
  const initialState =
    window && process.env.NODE_ENV !== "development"
      ? {
          config: {
            apiUrl: window.INITIAL_STATE?.config?.DOMAIN,
            imageBaseUrl: window.INITIAL_STATE?.config?.IMAGE_BASE_URL
          },
        }
      : {
          config: {
            apiUrl: "http://localhost:8080",
            imageBaseUrl: process.env.IMAGE_BASE_URL //Please use dev url in local to use if this doesnt work
          },
        };
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== "production",
  });

  return store;
};

export default configureAppStore;
