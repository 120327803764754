import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles/index.js";
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import AddApplication from "../Applications/ApplicationDetails/Transactions/AddApplication.js";
import apiRequest from "../../../utils/api.js";
import moment from "moment";
import CommonTable from "../../common/CommonTable.js";
import { Pagination } from "@mui/material";
import {
  AddRounded,
  ArrowBackIos,
  ArrowForwardIos,
  Download,
  FilterAlt,
  SearchOutlined,
  Settings,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import FiltersDrawer from "../../common/FiltersDrawer.js";
import AppplicationListFilters from "./AppplicationListFilters.js";
import CommonButton from "../../common/CommonButton.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const CustomPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-previousNext": {
    display: "none",
  },
}));

const ApplicationList = () => {
  const [openAddApplication, setOpenAddApplication] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState({});
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const openAddApplicationModal = () => {
    setOpenAddApplication(!openAddApplication);
  };

  const handleChangeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText.length >= 3 || searchText === "") {
      setPage(1);
      const handler = setTimeout(() => {
        setDebouncedSearch(searchText);
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [searchText]);

  const fetchTransactionListData = useCallback(
    async (searchText) => {
      const accessToken = localStorage.getItem("token");

      const res = await apiRequest("/list-application", "POST", {
        token: accessToken,
        page: page,
        searchText: debouncedSearch,
        ...filters,
      });
      if (res.success) {
        setTransactionData(res.data.applicationsList);
        setTotalPages(res?.data?.totalCount);
      }
    },
    [page, debouncedSearch, filters]
  );

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page < Math.ceil(totalPages / 10)) {
      setPage(page + 1);
    }
  };
  const tableHeaders = [
    "Application ID",
    "Name",
    "App-Id",
    "Email",
    "Created On",
    "Created By",
  ];

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const renderRow = (row) => {
    return (
      <>
        <StyledTableCell component="th" scope="row">
          {row?.applicationId ? row.applicationId : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.name ? row.name : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.appId ? row.appId : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.email ? row.email : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {moment(row.createdDate).format("DD/MM/YYYY") || "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.createdBy ? row.createdBy : "NA"}
        </StyledTableCell>
      </>
    );
  };

  const handleRowClick = (row) => {
    navigate(`/applications/${row._id}`);
  };

  useEffect(
    (deboucedSearch) => {
      if (deboucedSearch) {
        fetchTransactionListData();
      } else {
        fetchTransactionListData();
      }
    },
    [page, fetchTransactionListData, debouncedSearch, filters]
  );
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box className="border-[1px] rounded-lg w-full application-list">
      <div className="flex justify-between items-center">
        <div>
          <Typography variant="h6" className="!font-semibold p-6" gutterBottom>
            Application List
          </Typography>
        </div>
        <div className="flex gap-2">
          <TextField
            className="w-96"
            placeholder="Search Applications"
            type="search"
            size="small"
            onChange={handleChangeSearchText}
            value={searchText}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchOutlined />
                </IconButton>
              ),
            }}
          />
          <CommonButton
            text="Add Aplications"
            bgColor="black"
            onClick={openAddApplicationModal}
            StartIcon={<AddRounded />}
          />
          <IconButton>
            <Download className="bg-slate-100" />
          </IconButton>
          <CommonButton
            text="Filter"
            bgColor="white"
            onClick={openDrawer}
            StartIcon={<FilterAlt className="black" />}
          />
          <IconButton>
            <Settings className="bg-slate-100" />
          </IconButton>
        </div>
      </div>
      <div className="overflow-x-auto">
        <CommonTable
          headers={tableHeaders}
          data={transactionData}
          renderRow={renderRow}
          handleRowClick={handleRowClick}
        />

        <AddApplication
          open={openAddApplication}
          close={openAddApplicationModal}
        />
      </div>
      <div className="mt-2 flex justify-between p-6 w-full">
        <CommonButton
          text="Previous"
          disabled={page === 1}
          onClick={handlePrevious}
          StartIcon={<ArrowBackIos fontSize="small" />}
        />
        <CustomPagination
          count={Math.ceil(totalPages / 10)}
          page={page}
          onChange={handlePageChange}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          shape="rounded"
        />
        <CommonButton
          text="Next"
          disabled={page === Math.ceil(totalPages / 10)}
          onClick={handleNext}
          EndIcon={<ArrowForwardIos fontSize="small" />}
        />
      </div>
      <FiltersDrawer open={isDrawerOpen} onClose={closeDrawer}>
        <AppplicationListFilters
          setFilters={setFilters}
          closeDrawer={closeDrawer}
        />
      </FiltersDrawer>
    </Box>
  );
};

export default ApplicationList;
