import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, Tab, Tabs, Card } from "@mui/material";
import ReactApexChart from "react-apexcharts/dist/react-apexcharts.js";
import {
  SyncAlt as SyncAltIcon,
  DoneOutlined as DoneOutlinedIcon,
  AccessTime as AccessTimeIcon,
  Clear as ClearIcon,
} from "@mui/icons-material/index.js";
import TransactionCard from "./TransactionCard.js";
import apiRequest from "../../utils/api.js";
import CustomTabPanel from "../common/CustomTabPanel.js";

const categories = ["buy", "sell", "exchange", "send", "receive"];

const Dashboard = () => {
  const [value, setValue] = useState(0);
  const [transactionCount, setTransactionCount] = useState({});
  const [transactionStatusCount, setTransactionStatusCount] = useState({});
  const [selectedFilter, setSelectedFilter] = useState("1D");
  const [graphData, setGraphData] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const options = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    xaxis: {
      categories: graphData?.labels,
    },
    yaxis: {
      labels: {
        // Format labels with "k"
        formatter: (value) => {
          if (value >= 1000) {
            return `${Math.floor(value / 1000)}k`;
          }
          return Math.floor(value);
        },
        show: true, // Show y-axis labels
      },
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
  };

  const series = [
    {
      name: "count",
      data: graphData?.counts || [],
    },
  ];

  const dateItems = [
    { label: "All Time", key: "Y" },
    { label: "6M", key: "6M" },
    { label: "3M", key: "3M" },
    { label: "2W", key: "2W" },
    { label: "1D", key: "1D" },
  ];
  const handleDateFilter = (item) => {
    setSelectedFilter(item.key);
  };

  const getTransactionCount = useCallback(async () => {
    try {
      const res = await apiRequest("/get-transaction-count", "POST", {
        filterType: selectedFilter,
      });
      if (res?.success) {
        const data = res?.data;
        const totalResult = {
          Completed: { amount: 0, count: 0 },
          Processing: { amount: 0, count: 0 },
          Failed: { amount: 0, count: 0 },
          total: { amount: 0, count: 0 },
        };
        categories?.forEach((category) => {
          totalResult.Completed.count += data[category]?.Completed?.count;
          totalResult.Processing.count += data[category]?.Processing?.count;
          totalResult.Failed.count += data[category]?.Failed?.count;
          totalResult.total.count += data[category]?.total?.count;

          totalResult.Completed.amount += data[category]?.Completed?.amount;
          totalResult.Processing.amount += data[category]?.Processing?.amount;
          totalResult.Failed.amount += data[category]?.Failed?.amount;
          totalResult.total.amount += data[category]?.total?.amount;
        });
        setTransactionCount({ data, totalResult });
      }
    } catch (err) {
      console.error(err);
    }
  }, [selectedFilter]);

  const getTransactionStatusCount = useCallback(async () => {
    try {
      const res = await apiRequest("/get-transaction-status-count", "POST", {
        filterType: selectedFilter,
      });
      if (res?.success) {
        setTransactionStatusCount(res?.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [selectedFilter]);

  const getGraphData = useCallback(async () => {
    try {
      const res = await apiRequest("/get-transaction-count-by-period", "POST", {
        filterType: selectedFilter,
      });
      if (res?.success) {
        const labels = Object.keys(res?.data);
        const counts = Object.values(res?.data);
        setGraphData({ labels, counts });
      }
    } catch (err) {
      console.error(err);
    }
  }, [selectedFilter]);

  useEffect(() => {
    const getData = async () => {
      await Promise.all([
        getTransactionCount(),
        getTransactionStatusCount(),
        getGraphData(),
      ]);
    };
    getData();
  }, [
    selectedFilter,
    getTransactionCount,
    getTransactionStatusCount,
    getGraphData,
  ]);

  return (
    <Box>
      <Box className="h-full w-full">
        <Box className="border-b-[1px] flex md:flex-row flex-col justify-between py-2">
          <Typography variant="h5" className="p-2 !font-semibold ">
            Dashboard
          </Typography>
          <div className="flex gap-[8px]">
            {dateItems.map((item) => (
              <div
                key={item.key}
                className={`p-[8px] flex items-center justify-center cursor-pointer hover:bg-[#E4E4E7] hover:rounded-lg ${
                  selectedFilter.includes(item.key)
                    ? "border-b-2 border-primary bg-[#D1D1D6] rounded-lg"
                    : ""
                }`}
                onClick={() => handleDateFilter(item)}
              >
                {item.label}
              </div>
            ))}
          </div>
        </Box>
        <Tabs
          className="pt-4 border-b-[2px]"
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "black",
            },
          }}
        >
          <Tab
            label="Transactions"
            sx={{
              "&.Mui-selected": {
                color: "black",
              },
            }}
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "black",
              },
            }}
            label="Example"
            {...a11yProps(1)}
          />
        </Tabs>

        <CustomTabPanel value={value} index={0}>
          <Box className="flex gap-6 mb-8 md:flex-row flex-col">
            <Box className="border-[1px] rounded-lg p-2 md:w-[70%] w-full">
              <p className="font-medium text-lg pl-2">Transactions</p>
              <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="400"
              />
            </Box>
            <Box className="md:w-[30%] w-full flex flex-col gap-6">
              <Card variant="outlined" className="!rounded-lg py-6 px-4">
                <Box className="flex justify-between">
                  <span className="rounded-full p-4 bg-[#dedede95]">
                    <SyncAltIcon />
                  </span>
                  <Box className="flex flex-col items-end">
                    <p className="text-2xl font-medium">
                      {transactionStatusCount?.totalTransactionCount}
                    </p>
                    <p>Total Transactions</p>
                  </Box>
                </Box>
              </Card>
              <Card variant="outlined" className="!rounded-lg py-6 px-4">
                <Box className="flex justify-between">
                  <span className="rounded-full p-4 bg-[#dedede95]">
                    <DoneOutlinedIcon />
                  </span>
                  <Box className="flex flex-col items-end">
                    <p className="text-2xl font-medium">
                      {transactionStatusCount?.totalCompleted}
                    </p>
                    <p className="text-green-600">Completed</p>
                  </Box>
                </Box>
              </Card>
              <Card variant="outlined" className="!rounded-lg py-6 px-4">
                <Box className="flex justify-between">
                  <span className="rounded-full p-4 bg-[#dedede95]">
                    <AccessTimeIcon />
                  </span>
                  <Box className="flex flex-col items-end">
                    <p className="text-2xl font-medium">
                      {transactionStatusCount?.totalProcessing}
                    </p>
                    <p className="text-blue-600">Processing</p>
                  </Box>
                </Box>
              </Card>
              <Card variant="outlined" className="!rounded-lg py-6 px-4">
                <Box className="flex justify-between">
                  <span className="rounded-full p-4 bg-[#dedede95]">
                    <ClearIcon />
                  </span>
                  <Box className="flex flex-col items-end">
                    <p className="text-2xl font-medium">
                      {transactionStatusCount?.totalFailed}
                    </p>
                    <p className="text-red-600">Failed</p>
                  </Box>
                </Box>
              </Card>
            </Box>
          </Box>

          {/* Render Cards */}
          <Box className="w-[100%] flex flex-wrap gap-4 justify-between">
            <TransactionCard
              title={"All Transactions"}
              data={transactionCount?.totalResult}
            />
            <TransactionCard title={"Buy"} data={transactionCount?.data?.buy} />
            <TransactionCard
              title={"Sell"}
              data={transactionCount?.data?.sell}
            />
            <TransactionCard
              title={"Exchange"}
              data={transactionCount?.data?.exchange}
            />
            <TransactionCard
              title={"Send"}
              data={transactionCount?.data?.send}
            />
            <TransactionCard
              title={"Receive"}
              data={transactionCount?.data?.receive}
            />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          Item Two
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default Dashboard;
