import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { SearchOutlined, RestartAlt } from "@mui/icons-material";
import CommonDatePicker from "../../common/CommonDatePicker.js";
import "../css/filters.css";
import FilterTextField from "../../common/FilterTextField.js";

const AppplicationListFilters = (props) => {
  const [inputFields, setInputFields] = useState({
    name: "",
    applicationId: "",
    fromDate: "",
    toDate: "",
  });
  const { setFilters, closeDrawer } = props;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = () => {
    setFilters(inputFields);
    closeDrawer();
  };

  const reset = () => {
    setFilters({});
    setInputFields({
      name: "",
      applicationId: "",
      fromDate: "",
      toDate: "",
    });
  };

  const setDateRange = (startDate, endDate) => {
    setInputFields((prevState) => ({
      ...prevState,
      fromDate: startDate,
      toDate: endDate,
    }));
  };

  return (
    <Box className="flex flex-col justify-between h-full">
      <Box className="mt-8 filter-drawer flex gap-4">
        <FilterTextField
          label="Account ID"
          name="applicationId"
          placeholder="Search by ID"
          handleInputChange={handleInputChange}
          value={inputFields?.applicationId}
        />
        <FilterTextField
          label="Name"
          name="name"
          placeholder="Search by name"
          handleInputChange={handleInputChange}
          value={inputFields?.name}
        />
        <Box>
          <p className="text-sm mb-2 font-normal text-[#51525C]">Date</p>
          <CommonDatePicker setDateRange={setDateRange} />
        </Box>
      </Box>
      <Box className="flex self-end gap-2">
        <Button size="large" variant="outlined" id="reset" onClick={reset}>
          <RestartAlt className="mr-2" />
          Reset
        </Button>
        <Button size="large" variant="contained" id="search" onClick={onSubmit}>
          <SearchOutlined className="mr-2" />
          Search
        </Button>
      </Box>
    </Box>
  );
};

export default AppplicationListFilters;
