import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "../components/Login/Login.js";
import Dashboard from "../components/Dashboard/Dashboard.js";
import SideNav from "../components/SideNavBar/SideNav.js";
import Layout from "../components/layout/Layout.js";
import ApplicationDetails from "../components/GlobalSettings/Applications/ApplicationDetails/ApplicationDetails.js";
import TransactionList from "../components/Transactions/TransactionList.js";
import TransactionDetails from "../components/Transactions/TransactionDetails.js";
import WalletList from "../components/Wallets/WalletList.js";
import WalletDetails from "../components/Wallets/WalletDetails.js";
import GlobalSettings from "../components/GlobalSettings/GlobalSettings.js";

const RoutesList = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route
          exact
          path="/"
          element={
            <Layout>
              <SideNav children={<Dashboard />} heading={"Dashboard"} />
            </Layout>
          }
        />
        <Route
          path="/applications/:id"
          element={
            <Layout>
              <SideNav
                children={<ApplicationDetails />}
                heading={"Application Details"}
              />
            </Layout>
          }
        />
        <Route
          path="/transactions"
          element={
            <Layout>
              <SideNav
                children={<TransactionList />}
                heading={"Transaction History"}
              />
            </Layout>
          }
        />
        <Route
          path="/transactions/:id"
          element={
            <Layout>
              <SideNav
                children={<TransactionDetails />}
                heading={"Transaction Details"}
              />
            </Layout>
          }
        />
        <Route
          path="/wallets"
          element={
            <Layout>
              <SideNav children={<WalletList />} heading={"Wallet List"} />
            </Layout>
          }
        />
        <Route
          path="/wallets/:_id/:addressKey"
          element={
            <Layout>
              <SideNav
                children={<WalletDetails />}
                heading={"Wallet Details"}
              />
            </Layout>
          }
        />
        <Route
          path="/global-settings"
          element={
            <Layout>
              <SideNav
                children={<GlobalSettings />}
                heading={"Global Settings"}
              />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
};

export default RoutesList;
