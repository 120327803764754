import RoutesList from "./routes/Routes.js";
import CryptoLoader from "./components/common/CryptoLoader.js";
import { useSelector } from "react-redux";
import AxiosInterceptor from "./components/common/AxiosInterceptor.js";
import { CssBaseline,createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
  },
});

function App() {
  const loading = useSelector((state) => state.loading?.loading?.isLoading);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        {loading && <CryptoLoader />}
        <AxiosInterceptor />
        <RoutesList />
      </div>
    </ThemeProvider>
  );
}

export default App;
