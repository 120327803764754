import { combineReducers } from "redux";
import globalSettingsReducer from "./globalSettingsReducer.js";
import loadingReducer from "./commonReducer.js";
import configReducer from "./configReducer.js";

const rootReducer = combineReducers({
  config: configReducer,
  globalSettings: globalSettingsReducer,
  loading: loadingReducer,
});

export default rootReducer;
