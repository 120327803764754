import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { RemoveRedEye as RemoveRedEyeIcon } from "@mui/icons-material";
import { VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";

const PasswordField = ({
  textInput,
  handleChange,
  isPasswordVisible,
  setIsPasswordVissible,
}) => {
  return (
    <div className="flex flex-col">
      <div className="font-medium text-sm text-slate-700 mb-1">Password</div>
      <TextField
        sx={{
          width: 300,
          background: "white",
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
          },
        }}
        placeholder="Enter your password"
        type={isPasswordVisible ? "text" : "password"}
        variant="outlined"
        name="password"
        value={textInput?.password}
        onChange={handleChange}
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setIsPasswordVissible(!isPasswordVisible)}
              >
                {isPasswordVisible ? (
                  <VisibilityOffIcon />
                ) : (
                  <RemoveRedEyeIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
export default PasswordField;
