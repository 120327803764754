import React from "react";
import { Box, TextField, InputAdornment } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";

const FilterTextField = (props) => {
  const { label, name, placeholder, handleInputChange, value } = props;
  return (
    <Box>
      <p className="text-sm mb-2 font-normal text-[#51525C]">{label}</p>
      <TextField
        name={name}
        placeholder={placeholder}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined />
            </InputAdornment>
          ),
        }}
        onChange={handleInputChange}
        value={value}
        size="small"
      />
    </Box>
  );
};

export default FilterTextField;
