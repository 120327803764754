import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const CommonTable = ({
  headers,
  data,
  renderRow,
  handleRowClick,
  approxUSDBalance,
}) => {
  return (
    <TableContainer className="border-t-[1px] border-b-[1px]">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className="bg-gray-100">
          <TableRow>
            {headers.map((item, index) => {
              return (
                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  className="!font-semibold !text-[#51525C]"
                  key={index}
                >
                  {item}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 ? (
            data?.map((row, index) => (
              <TableRow
                onClick={() => {
                  handleRowClick && handleRowClick(row);
                }}
                className="cursor-pointer"
                key={index}
              >
                {renderRow(row)}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell>No records</TableCell>
            </TableRow>
          )}
          {approxUSDBalance && (
            <TableRow className="">
              <TableCell align="left">Approx. USD Balance</TableCell>
              <TableCell>{`${approxUSDBalance}`}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommonTable;
