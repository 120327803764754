import React from "react";
import { Box } from "@mui/material";
import CopyButton from "../../common/CopyButton.js";
import {
  ShoppingCartOutlined,
  ArrowDownwardOutlined,
  SyncAlt,
  SendOutlined,
} from "@mui/icons-material";
import moment from "moment";

const TransactionInfoTab = ({ data }) => {
  return (
    <Box className="border-[1px] rounded-lg m-6 p-6 text-sm flex flex-col gap-2">
      <Box className="flex items-center justify-between">
        <p className="text-gray-600">Account ID:</p>
        <p>
          {data?.application?.applicationId
            ? data?.application?.applicationId
            : "N/A"}{" "}
          <CopyButton textToCopy={data?.application?.applicationId} />
        </p>
      </Box>
      <hr />
      <Box className="flex items-center justify-between">
        <p className="text-gray-600">Transaction Type:</p>
        <p>
          {data?.type === "buy" && <ShoppingCartOutlined />}
          {data?.type === "sell" && <ShoppingCartOutlined />}
          {data?.type === "send" && <SendOutlined />}
          {data?.type === "exchange" && <SyncAlt />}
          {data?.type === "receive" && <ArrowDownwardOutlined />}

          <span className="p-2"> {data?.type ? data?.type : "N/A"}</span>
        </p>
      </Box>
      <hr />
      <Box className="flex items-center justify-between">
        <p className="text-gray-600">Transaction Initiated:</p>
        <p className="p-2">{data?.createdDate ? moment(data?.createdDate).format("DD/MM/YYYY - hh:mm:ss") : "N/A"}</p>
      </Box>
      <hr />
      <Box className="flex items-center justify-between">
        <p className="text-gray-600">Last Updated:</p>
        <p className="p-2">
          {data?.lastUpdatedDate ? moment(data?.lastUpdatedDate).format("DD/MM/YYYY - hh:mm:ss") : "N/A"}
        </p>
      </Box>
      <hr />
      <Box className="flex items-center justify-between">
        <p className="text-gray-600">Transaction Status:</p>
        <p className="p-2">{data?.status?.code ? data?.status?.code : "N/A"}</p>
      </Box>
      <hr />
      <Box className="flex items-center justify-between">
        <p className="text-gray-600">Transaction ID:</p>
        <p>
          {data?.transactionId ? data?.transactionId : "N/A"}{" "}
          <CopyButton textToCopy={data?.transactionId} />
        </p>
      </Box>
      <Box className="flex items-center justify-between">
        <p className="text-gray-600">Blockchain:</p>
        <p>
          {data?.blockchain ? data?.blockchain : "N/A"}{" "}
          <CopyButton textToCopy={data?.blockchain } />
        </p>
      </Box>
      <Box className="flex items-center justify-between">
        <p className="text-gray-600">Network:</p>
        <p>
          {data?.network ? data?.network : "N/A"}{" "}
          <CopyButton textToCopy={data?.network} />
        </p>
      </Box>
      <Box className="flex items-center justify-between">
        <p className="text-gray-600">Crypto ID:</p>
        <p>
          {data?.cryptoSymbol ? data?.cryptoSymbol : "N/A"}{" "}
          <CopyButton textToCopy={data?.cryptoSymbol} />
        </p>
      </Box>
      <hr />
      {data?.fromWallet && (
        <>
          <Box className="flex items-center justify-between">
            <p className="text-gray-600">From Wallet:</p>
            <p>
              {data?.fromWallet?.address ? data?.fromWallet?.address : "N/A"}{" "}
              <CopyButton textToCopy={data?.fromWallet?.address} />
            </p>
          </Box>
        </>
      )}
      {data?.toWallet && (
        <>
          <Box className="flex items-center justify-between">
            <p className="text-gray-600">To Wallet:</p>
            <p>
              {data?.toWallet?.address ? data?.toWallet?.address : "N/A"}{" "}
              <CopyButton textToCopy={data?.toWallet?.address} />
            </p>
          </Box>
        </>
      )}
      <Box className="flex items-center justify-between">
        <p className="text-gray-600">Crypto Amount:</p>
        <p>
          {data?.cryptoAmount ? data?.cryptoAmount : "N/A"}{" "}
          <CopyButton textToCopy={data?.cryptoAmount} />
        </p>
      </Box>
      <Box className="flex items-center justify-between">
        <p className="text-gray-600">Fiat Amount:</p>
        <p>
          $ {data?.fiatAmount ? data?.fiatAmount : "N/A"}{" "}
          <CopyButton textToCopy={data?.fiatAmount} />
        </p>
      </Box>

      {data?.autoTrade && (
        <>
          <Box className="flex items-center justify-between">
            <p className="text-gray-600">Trade ID:</p>
            <p>
              {data?.autoTrade?.tradeOrderId
                ? data?.autoTrade?.tradeOrderId
                : "N/A"}
              <CopyButton textToCopy={data?.autoTrade?.tradeOrderId} />
            </p>
          </Box>
          <Box className="flex items-center justify-between">
            <p className="text-gray-600">Currency:</p>
            <p>
              {data?.autoTrade?.sendOrReceiveCurrency
                ? data?.autoTrade?.sendOrReceiveCurrency
                : "N/A"}{" "}
              <CopyButton textToCopy={data?.autoTrade?.sendOrReceiveCurrency} />
            </p>
          </Box>
          <Box className="flex items-center justify-between">
            <p className="text-gray-600">Quantity:</p>
            <p>
              {data?.autoTrade?.sendOrReceiveQuantity
                ? data?.autoTrade?.sendOrReceiveQuantity
                : "N/A"}{" "}
              <CopyButton textToCopy={data?.autoTrade?.sendOrReceiveQuantity} />
            </p>
          </Box>
        </>
      )}
    </Box>
  );
};

export default TransactionInfoTab;
