import * as React from "react";
import {
  Box,
  Drawer as MuiDrawer,
  CssBaseline,
  AppBar as MuiAppBar,
  Toolbar,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
  List,
} from "@mui/material";
import {
  Menu as MenuIcon,
  DragHandle,
  Logout,
  SyncAlt,
  WalletOutlined,
  GridView,
  Settings,
} from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  zIndex: 1301, // Ensures DrawerHeader is above the AppBar
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 1200, // Lower z-index to allow Drawer to overlap
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#f7f7f7", // Set the AppBar background color
  color: "black", // Change the text color to contrast with the light background
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "#97180C", // Set the Drawer background color
  zIndex: 1300, // Higher z-index to overlap the AppBar
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      backgroundColor: "#97180C", // Set the Drawer background color
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      backgroundColor: "#97180C", // Set the Drawer background color
    },
  }),
}));

const SideNav = ({ heading, children }) => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Use the hook here

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleOpenClose = () => {
    setOpen(!open);
  };

  const menuItems = [
    { text: "Dashboard", icon: <GridView />, path: "/" },
    { text: "Transactions", icon: <SyncAlt />, path: "/transactions" },
    { text: "Wallets", icon: <WalletOutlined />, path: "/wallets" },
    {
      text: "Global Settings",
      icon: <Settings />,
      path: "/global-settings",
    },
  ];

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <Box className="flex">
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ marginRight: 5, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            className="!font-semibold"
            variant="h6"
            noWrap
            component="div"
          >
            {heading}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          sx={{
            justifyContent: "center",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
          }}
        >
          {open && (
            <>
              <Box
                className="w-48 cursor-pointer"
                onClick={handleOpenClose}
                component="img"
                src="/navImage.png"
                alt="Logo"
              />
            </>
          )}
          {!open && (
            <IconButton onClick={handleOpenClose}>
              <DragHandle sx={{ color: "white" }} fontSize="large" />
            </IconButton>
          )}
        </DrawerHeader>

        <Divider />
        <List className="h-full flex flex-col justify-between">
          <div>
            {menuItems.map(({ text, icon, path }) => (
              <ListItem
                key={text}
                disablePadding
                sx={{ display: "block" }}
                className="px-3"
              >
                <ListItemButton
                  component={Link}
                  to={path}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    backgroundColor:
                      location.pathname === path ? "#E62E05" : "inherit", // Selection color
                    "&:hover": {
                      backgroundColor: "#BC1B06", // Hover color
                    },
                    color: "white", // Text and icon color
                    border:
                      location.pathname === path ? "2px solid #E62E05" : "none", // Border for selected item
                    borderRadius: 1,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "inherit", // Icon color
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: "inherit", // Text color
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </div>

          <div>
            <ListItem
              key={"Logout"}
              disablePadding
              sx={{ display: "block" }}
              className="px-3 mb-4"
            >
              <ListItemButton
                onClick={logout}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  "&:hover": {
                    backgroundColor: "#BC1B06",
                  },
                  color: "white",
                  borderRadius: 1,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "inherit", // Icon color
                  }}
                >
                  <Logout />
                </ListItemIcon>
                <ListItemText
                  primary={"Logout"}
                  sx={{
                    opacity: open ? 1 : 0,
                    color: "inherit", // Text color
                  }}
                />
              </ListItemButton>
            </ListItem>
          </div>
        </List>
      </Drawer>
      <Box
        component="main"
        className="overflow-hidden"
        sx={{ flexGrow: 1, p: 3, position: "relative", minHeight: '100vh' }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};

export default SideNav;
