import React from "react";
import { Box, Select, FormControl, InputLabel,OutlinedInput } from "@mui/material";

const FilterDropdown = (props) => {
  const { label, value, name, children, handleInputChange, isMultiple,placeholder } = props;
  return (
    <Box className="!rounded-xl w-60">
      <p className="text-sm mb-2 font-normal text-[#51525C]">{label}</p>
      <FormControl fullWidth size="small">
      <InputLabel id="demo-multiple-checkbox-label">{placeholder}</InputLabel>
        <Select
          id="demo-simple-select"
          name={name}
          multiple={isMultiple}
          value={value}
          onChange={handleInputChange}
          input={<OutlinedInput label={placeholder} />}
        >
          {children}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FilterDropdown;
