import { Modal } from "@mui/material";
import React from "react";
import CommonButton from "../../common/CommonButton.js";

const Success = (props) => {
  const handleClick = () => {
    props.close();
    props.closeNotificationModal();
    window.location.reload();
  };

  return (
    <Modal open={true} className="flex  justify-center items-center">
      <div className="flex flex-col items-center gap-6 bg-zinc-100 p-8 h-max rounded-xl edit-balance">
        <img src="/success.svg" height="108px" width="108px" alt="SUCCESS" />
        <p>SUCCESS</p>
        <p>Notification sent successfully</p>
        <CommonButton
          text="Close"
          bgColor="black"
          width="w-full"
          borderRadius="rounded-3xl"
          onClick={handleClick}
        />
      </div>
    </Modal>
  );
};
export default Success;
